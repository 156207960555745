/* Mixins */
.page-body[data-v-330375de] {
  background: #fff;
  padding: 20px;
}
.header-wrapper[data-v-330375de] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.header-wrapper .header-left-wrapper[data-v-330375de] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
}
.header-wrapper .header-middle-wrapper[data-v-330375de] {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.header-wrapper .header-right-wrapper[data-v-330375de] {
  min-width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-right: 30px;
  position: relative;
}
.header-wrapper .header-right-wrapper .setting-icon[data-v-330375de] {
  font-size: 20px;
}
.header-wrapper .header-right-wrapper .setting-icon[data-v-330375de]:hover {
  cursor: pointer;
  color: #2393cd;
}
.header-wrapper .header-right-wrapper .showType[data-v-330375de] {
  position: absolute;
  background: #fff;
  border: 1px #cdcdcd solid;
  /* width: 70px; */
  /* text-align: center; */
  top: 30px;
  right: -70px;
  z-index: 99;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.header-wrapper .header-right-wrapper .showType div[data-v-330375de] {
  margin: 5px;
  padding: 5px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}
.header-wrapper .header-right-wrapper .showType div[data-v-330375de]:hover {
  cursor: pointer;
  color: #2393cd;
}
.header-wrapper .header-right-wrapper div[data-v-330375de]:first-child {
  margin-right: 10px;
}
.right-month-picker[data-v-330375de] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 20px;
}
.calendar-item[data-v-330375de] {
  margin-bottom: 8px;
}
.previous[data-v-330375de] {
  background: #DFDFDF;
}
.today[data-v-330375de] {
  background: #FFFCCA;
}